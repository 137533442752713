import React from "react";
import Layout from "gatsby-plugin-based-on-staticfuse/src/components/Layout";
import SEO from "gatsby-plugin-based-on-staticfuse/src/components/SEO";
import { useStaticQuery, graphql } from "gatsby";
import { Box, Heading } from "@chakra-ui/core";

const PrivacyPolicy = ({ location }) => {
  const data = useStaticQuery(graphql`
    {
      pageContent: wpgraphql {
        text: pageBy(pageId: 166) {
          slug
          content
        }
      }
    }
  `);
  return (
    <Layout location={{ location }} fullWidth="true">
      <SEO title="Privacy Policy" />

      <Box maxW="3xl" m="auto" py={4} my={4} px={2}>
        <Heading as="h1" fontWeight="600" size="xl" lineHeight="1.1" my={8}>
          Privacy Policy
        </Heading>

        <div
          dangerouslySetInnerHTML={{ __html: data.pageContent.text.content }}
        ></div>
      </Box>

      <Box className="row-wrapper" px={2} mb={4}>
        <Box maxW="3xl" m="auto">
          <Box
            display={["block", "grid"]}
            gridTemplateColumns="repeat(auto-fit, minmax(100px, 1fr))"
            gridColumnGap="4%"
            my={2}
            pb={4}
          ></Box>
        </Box>
      </Box>
    </Layout>
  );
};

export default PrivacyPolicy;
